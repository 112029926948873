<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.00065 10.0001C3.44732 10.0001 2.94732 10.2268 2.58732 10.5868C1.80065 11.3735 1.33398 14.6668 1.33398 14.6668C1.33398 14.6668 4.62732 14.2001 5.41398 13.4135C5.77398 13.0535 6.00065 12.5535 6.00065 12.0001C6.00065 10.8935 5.10732 10.0001 4.00065 10.0001ZM4.47398 12.4735C4.28732 12.6601 3.02732 12.9801 3.02732 12.9801C3.02732 12.9801 3.34065 11.7268 3.53398 11.5335C3.64732 11.4068 3.81398 11.3335 4.00065 11.3335C4.36732 11.3335 4.66732 11.6335 4.66732 12.0001C4.66732 12.1868 4.59398 12.3535 4.47398 12.4735ZM11.614 9.10012C15.854 4.86012 14.4406 1.56012 14.4406 1.56012C14.4406 1.56012 11.1407 0.146785 6.90065 4.38678L5.24065 4.05345C4.80732 3.96678 4.35398 4.10678 4.03398 4.42012L1.33398 7.12678L4.66732 8.55345L7.44732 11.3335L8.87398 14.6668L11.574 11.9668C11.8873 11.6535 12.0273 11.2001 11.9406 10.7601L11.614 9.10012ZM4.94065 7.22012L3.66732 6.67345L4.98065 5.36012L5.94065 5.55345C5.56065 6.10678 5.22065 6.68678 4.94065 7.22012ZM9.32732 12.3335L8.78065 11.0601C9.31398 10.7801 9.89398 10.4401 10.4406 10.0601L10.634 11.0201L9.32732 12.3335ZM10.6673 8.16012C9.78732 9.04012 8.41398 9.76012 7.97398 9.98012L6.02065 8.02678C6.23398 7.59345 6.95398 6.22012 7.84065 5.33345C10.9607 2.21345 13.3273 2.67345 13.3273 2.67345C13.3273 2.67345 13.7873 5.04012 10.6673 8.16012ZM10.0007 7.33345C10.734 7.33345 11.334 6.73345 11.334 6.00012C11.334 5.26678 10.734 4.66678 10.0007 4.66678C9.26732 4.66678 8.66732 5.26678 8.66732 6.00012C8.66732 6.73345 9.26732 7.33345 10.0007 7.33345Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IcRocket',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
  },
}
</script>

<style scoped lang="scss"></style>
