<template>
  <div class="px-8 py-6 space-y-6 max-w-150 w-full container-height">
    <div class="flex items-center gap-3">
      <h4 class="h4">Detect Infringement</h4>
      <ic-info />
    </div>

    <div class="space-y-8">
      <div>
        <h5 class="h5">Select the brand terms that should trigger infringement alerts</h5>
        <p class="text-sm">Use only your trademarked brand terms, exclude any generic terms.</p>
      </div>
      <div>
        <div class="flex flex-wrap gap-3">
          <chip-tag
            v-for="chip in computedSearchKeywords"
            :key="chip.value"
            :chip="chip"
            fully-clickable
            hover-style="w-6"
            :chip-style="
              chip.selected
                ? 'text-bb-blue bg-bb-blue-2 py-1 border border-bb-blue-4 rounded-lg pl-2 flex gap-2 items-center w-fit'
                : 'text-bb-brand-purple hover:text-bb-blue bg-bb-secondary-purple hover:bg-white py-1 border border-bb-purple-100 hover:border-bb-blue rounded-lg pl-2 flex gap-2 items-center w-fit'
            "
            :expanded="chip.selected"
            @select-chip="selectTrademark"
          >
            <template #right-icon>
              <div
                class="h-5 w-5 rounded-sm flex items-center justify-center"
                :class="chip.selected ? 'bg-bb-blue-3 text-bb-blue' : 'text-bb-disabled-buttons bg-neutral-0'"
              >
                <ic-guard
                  class="duration-200 ease-linear"
                  :size="14"
                />
              </div>
            </template>
          </chip-tag>
        </div>
      </div>

      <div class="space-y-2">
        <p class="text-sm">Missing a trademarked term? Add it here.</p>
        <text-input
          v-model="trademark"
          class="w-96"
          input-name="trademark"
          input-id="trademark"
          has-icon
          padding-right="pr-20"
          placeholder="Type here..."
          :error="duplicateTrademark"
          :error-message="duplicateTrademark ? 'This term already exists. Please enter a different term.' : ''"
          @keydown.enter="addTrademark"
          @keydown="duplicateTrademark = false"
        >
          <template #suffix>
            <div class="h-10 w-24 absolute right-0 top-0 p-2">
              <div
                class="flex items-center justify-center text-bb-brand-purple duration-200 ease-linear font-medium rounded-full hover:text-bb-text-default hover:bg-neutral-100 cursor-pointer"
                @click="addTrademark"
              >
                <ic-plus :size="16" />
                <p>Add</p>
              </div>
            </div>
          </template>
        </text-input>
      </div>

      <div v-if="hasLocations">
        <h5 class="h5">Location</h5>
      </div>
    </div>
  </div>
</template>

<script>
import ChipTag from '@/views/site/search/competitor_monitoring_v2/components/ChipTag.vue'
import IcGuard from '@/components/icon/brightbid/IcGuard.vue'
import IcPlus from '@/components/icon/ic-plus.vue'
import IcInfo from '@/components/icon/ic-info.vue'

import TextInput from '@/components/input/brightbid/TextInput.vue'

export default {
  name: 'SetupInfringementsDetector',
  components: { ChipTag, IcGuard, IcInfo, IcPlus, TextInput },
  props: {
    trademarks: {
      type: Array,
      default: () => [],
    },
    selectedTrademarks: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      trademark: '',
      duplicateTrademark: false,
    }
  },
  computed: {
    computedSearchKeywords() {
      return this.trademarks.map(item => {
        item.selected = this.selectedTrademarks.includes(item)
        return item
      })
    },
    hasLocations() {
      return true
    },
  },
  methods: {
    selectTrademark(trademark) {
      this.$emit('select-trademark', trademark)
    },
    addTrademark() {
      const isDuplicate = this.trademarks.some(item => item.value === this.trademark)
      if (!this.trademark) return
      if (isDuplicate) {
        this.$nextTick(() => {
          this.duplicateTrademark = true
        })
        return
      }
      this.$emit('add-trademark', { label: this.trademark, value: this.trademark })
      this.trademark = ''
    },
  },
}
</script>

<style scoped lang="scss">
.container-height {
  min-height: calc(100vh - 410px);
}
</style>
