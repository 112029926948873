import { assign, createMachine } from 'xstate'

export const STATES = {
  BRANDED_OR_GENERIC_MONITORING: 'BRANDED_OR_GENERIC_MONITORING',
  GENERIC_MONITORING: 'GENERIC_MONITORING',
  BRANDED_MONITORING: 'BRANDED_MONITORING',
  SETUP_INFRINGEMENTS_DETECTOR: 'SETUP_INFRINGEMENTS_DETECTOR',
  FINAL_STEP: 'FINAL_STEP',
}

export const EVENTS = {
  SET_MONITORING_TYPE: 'SET_MONITORING_TYPE',
  BACK: 'BACK',
  NEXT: 'NEXT',
}

const onboardingMachine = createMachine(
  {
    predictableActionArguments: true,
    id: 'cms-onboarding',
    initial: STATES.BRANDED_OR_GENERIC_MONITORING,
    context: {
      monitoringType: null,
      currentStep: 1,
    },
    states: {
      [STATES.BRANDED_OR_GENERIC_MONITORING]: {
        on: {
          [EVENTS.SET_MONITORING_TYPE]: {
            actions: ['assignMonitoringType'],
          },
          [EVENTS.NEXT]: [
            {
              actions: ['nextStep'],
              target: STATES.BRANDED_MONITORING,
              cond: context => context.monitoringType === 'branded-monitoring',
            },
            {
              actions: ['nextStep'],
              target: STATES.GENERIC_MONITORING,
              cond: context => context.monitoringType === 'generic-monitoring',
            },
          ],
        },
      },
      [STATES.BRANDED_MONITORING]: {
        on: {
          [EVENTS.NEXT]: {
            actions: ['nextStep'],
            target: STATES.SETUP_INFRINGEMENTS_DETECTOR,
          },
          [EVENTS.BACK]: {
            target: STATES.BRANDED_OR_GENERIC_MONITORING,
            actions: ['backToTheStart'],
          },
        },
      },
      [STATES.GENERIC_MONITORING]: {
        on: {
          [EVENTS.NEXT]: {
            target: STATES.FINAL_STEP,
          },
          [EVENTS.BACK]: {
            target: STATES.BRANDED_OR_GENERIC_MONITORING,
            actions: ['backToTheStart'],
          },
        },
      },
      [STATES.SETUP_INFRINGEMENTS_DETECTOR]: {
        on: {
          [EVENTS.NEXT]: {
            target: STATES.FINAL_STEP,
          },
          [EVENTS.BACK]: {
            target: STATES.BRANDED_MONITORING,
            actions: ['stepBack'],
          },
        },
      },
      [STATES.FINAL_STEP]: {
        final: true,
      },
    },
  },
  {
    actions: {
      assignMonitoringType: assign((context, event) => {
        if (!event.payload) return null
        return {
          monitoringType: event.payload.monitoringType,
        }
      }),
      stepBack: assign({
        currentStep: context => context.currentStep - 1,
      }),
      nextStep: assign({
        currentStep: context => context.currentStep + 1,
      }),
      backToTheStart: assign(() => {
        return {
          currentStep: 1,
        }
      }),
    },
  },
)

export default onboardingMachine
