<template>
  <div
    class="duration-200 ease-linear cursor-pointer"
    :class="chipStyle"
    @mouseenter="hoveredChip = chip.value"
    @mouseleave="hoveredChip = null"
    @click="$emit('select-chip', chip)"
  >
    <p>{{ chip.label }}</p>
    <div
      class="duration-200 ease-linear overflow-hidden"
      :class="
        hoveredChip === chip.value || expanded || !expandable
          ? `${hoverStyle} opacity-100 translate-x-0`
          : 'w-0 opacity-0 -translate-x-1/2'
      "
      @click="clickIcon"
    >
      <slot name="right-icon"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChipTag',
  props: {
    chipStyle: {
      type: String,
      default: 'bg-white rounded-md pl-2 flex gap-2 items-center w-fit',
    },
    chip: {
      type: Object,
      default: () => ({ label: '', value: '' }),
      required: true,
    },
    hoverStyle: {
      type: String,
      default: 'w-5',
    },
    fullyClickable: {
      type: Boolean,
      default: true,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
    expandable: {
      type: Boolean,
      default: true, // This prop will control if the chip can expand or not
    },
  },
  data() {
    return {
      hoveredChip: null,
    }
  },
  methods: {
    clickIcon(event) {
      if (this.fullyClickable) return
      event.stopPropagation()
      this.$emit('click-right-icon', this.chip)
    },
  },
}
</script>
