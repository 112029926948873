<template>
  <div class="w-full">
    <text-input
      v-model="input"
      input-name="search"
      input-id="search"
      input-type="text"
      placeholder="Search terms"
      padding-left="pl-10 mb-4"
      class="max-w-75"
    >
      <template #prefix>
        <div class="absolute z-10 h-10 p-2 text-bb-disabled-gray">
          <ic-search />
        </div>
      </template>
      <template #suffix>
        <div
          v-if="input"
          class="absolute z-10 right-0 top-0 h-10 py-2 px-1 text-bb-disabled-gray cursor-pointer"
          @click="input = ''"
        >
          <ic-cross class="p-1" />
        </div>
      </template>
    </text-input>
    <div class="border rounded-lg overflow-hidden">
      <div class="overflow-y-auto base-scrollbar max-h-55.5">
        <table class="w-full">
          <table-header
            :headers="headers"
            :sorting-order="sortingOrder"
            class="sticky top-0 bg-white z-10"
            @sort-table="sortTable"
          />
          <top-keywords-table-rows
            :keywords="sortedTableData"
            :selected-keywords="selectedKeywords"
            @add-keyword="$emit('add-keyword', $event)"
          />
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import TableHeader from '@/components/table/TableHeader.vue'
import TopKeywordsTableRows from './TopKeywordsTableRows.vue'
import TextInput from '@/components/input/brightbid/TextInput.vue'

import IcCross from '@/components/icon/ic-cross.vue'
import IcSearch from 'vue-material-design-icons/Magnify.vue'

import Fuse from 'fuse.js'
import _ from 'lodash'
import sortingModule from '@/utils/table/table-sorting'

export default {
  name: 'TopKeywordsTable',
  components: {
    IcCross,
    TextInput,
    IcSearch,
    TableHeader,
    TopKeywordsTableRows,
  },
  props: {
    keywords: {
      type: Array,
      required: true,
    },
    selectedKeywords: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        { value: 'keyword_text', label: 'Keyword', sortable: true, sorting: null, position: 'left' },
        { value: 'search_volume', label: 'Search Volume', sortable: true, sorting: null, position: 'left' },
        { value: 'clicks', label: 'Clicks', sortable: true, sorting: null, position: 'left' },
        { label: 'Action', sortable: true, sorting: null, position: 'left' },
      ],
      sortingOrder: { order: 'ASCENDING', column: 'clicks' },
      sortedTableData: [],
      input: '',
      query: '',
    }
  },
  computed: {
    filteredKeywords() {
      if (!this.query) return this.keywords
      const rules = {
        shouldSort: true,
        isCaseSensitive: false,
        keys: ['keyword_text'],
      }
      const fuse = new Fuse(this.keywords, rules)
      const results = fuse.search(this.query).map(result => result.item)
      if (results.length === 0 || !results.some(keyword => keyword.keyword === this.input)) {
        results.push({
          keyword_text: this.input,
          value: this.input,
          isNew: true,
        })
      }
      return results
    },
  },
  watch: {
    filteredKeywords: {
      immediate: true,
      handler(newVal) {
        this.sortedTableData = structuredClone(newVal)
      },
    },
    input(...args) {
      this.debounceQuery(...args)
    },
  },
  mounted() {
    this.debounceQuery = _.debounce(input => {
      this.query = input
    }, 300)
  },
  beforeUnmount() {
    this.debounceQuery.cancel()
  },
  methods: {
    sortTable(data) {
      const sortedData = sortingModule(data, this.sortedTableData)
      this.sortedTableData = sortedData.data
      this.sortingOrder = sortedData.order
      this.headers.find(header => header.value === sortedData.order.column).sorting = sortedData.order.order
    },
  },
}
</script>

<style scoped lang="scss"></style>
