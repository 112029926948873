<template>
  <tbody>
    <tr
      v-for="keyword in computedKeywords"
      :key="keyword.keyword_text"
      class="border-b capitalize"
      :class="{ 'text-bb-disabled-gray': keyword.isSelected }"
    >
      <td class="p-4">{{ keyword.keyword_text }} {{ keyword.keyword }}</td>
      <td class="p-4 px-8">{{ keyword.search_volume || 'N/A' }}</td>
      <td class="p-4 px-8">{{ keyword.clicks || 'N/A' }}</td>
      <td>
        <MergeButtonRound
          button-type="tertiary"
          :disabled="keyword.isSelected"
          class="h-6 w-6 px-7 mx-3"
          @click="addKeyword(keyword)"
        >
          {{ keyword.isSelected ? 'Added' : 'Add' }}
        </MergeButtonRound>
      </td>
    </tr>
  </tbody>
</template>

<script>
import MergeButtonRound from '@/components/btn/MergeButtonRound.vue'

export default {
  name: 'TopKeywordsTableRows',
  components: {
    MergeButtonRound,
  },
  props: {
    keywords: {
      type: Array,
      required: true,
    },
    selectedKeywords: {
      type: Array,
      required: true,
    },
  },
  computed: {
    computedKeywords() {
      return this.keywords.map(keyword => ({
        ...keyword,
        isSelected: this.isKeywordSelected(keyword),
      }))
    },
  },
  methods: {
    addKeyword(keyword) {
      this.$emit('add-keyword', keyword)
    },
    isKeywordSelected(keyword) {
      return this.selectedKeywords.some(
        selected => selected.label === keyword.keyword_text || selected.label === keyword.keyword,
      )
    },
  },
}
</script>

<style scoped lang="scss"></style>
