<template>
  <div class="container-height">
    <div>Generic Monitoring</div>
    <div @click="$emit('next')">NEXT</div>
    <div @click="$emit('back')">BACK</div>
  </div>
</template>

<script>
export default {
  name: 'GenericMonitoring',
}
</script>

<style scoped lang="scss">
.container-height {
  min-height: calc(100vh - 410px);
}
</style>
