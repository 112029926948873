<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.192 13.064C15.32 11.68 16 9.92 16 8C16 3.584 12.416 0 8 0C3.584 0 0 3.584 0 8C0 12.416 3.584 16 8 16C9.92 16 11.68 15.32 13.064 14.192C13.28 14.016 13.488 13.824 13.688 13.624C13.712 13.6 13.728 13.576 13.744 13.56C13.904 13.4 14.056 13.232 14.192 13.064ZM8 14.4C4.472 14.4 1.6 11.528 1.6 8C1.6 4.472 4.472 1.6 8 1.6C11.528 1.6 14.4 4.472 14.4 8C14.4 9.48 13.896 10.832 13.048 11.92L11.904 10.776C12.456 9.992 12.784 9.04 12.784 8.008C12.784 5.36 10.632 3.208 7.984 3.208C5.336 3.208 3.184 5.36 3.184 8.008C3.184 10.656 5.336 12.808 7.984 12.808C9.024 12.808 9.992 12.472 10.776 11.904L11.912 13.04C10.832 13.896 9.48 14.4 8 14.4ZM9.536 8.408C9.672 7.88 9.552 7.304 9.144 6.888L9.128 6.872C8.512 6.256 7.528 6.248 6.904 6.84C6.896 6.848 6.88 6.856 6.864 6.872C6.24 7.496 6.24 8.512 6.864 9.136L6.88 9.152C7.296 9.56 7.88 9.688 8.408 9.544L9.616 10.752C9.136 11.04 8.584 11.216 7.984 11.216C6.216 11.216 4.784 9.784 4.784 8.016C4.784 6.248 6.216 4.816 7.984 4.816C9.752 4.816 11.184 6.248 11.184 8.016C11.184 8.6 11.016 9.144 10.736 9.616L9.536 8.408Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IcGeneric',
  props: {
    size: {
      type: [String, Number],
      default: 16,
    },
  },
}
</script>

<style scoped lang="scss"></style>
