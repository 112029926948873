var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-bb-text-default space-y-6 py-6 container-height"},[_vm._m(0),_c('div',{staticClass:"px-8 space-y-5"},_vm._l((_vm.monitoringOptions),function(option){return _c('div',{key:option.value,staticClass:"relative p-3 flex items-start gap-6 w-full border rounded-2xl duration-300 ease-linear cursor-pointer",class:{
        'border-neutral-100': _vm.hoveredOption !== option.value,
        'blue-border':
          option.value === 'branded-monitoring' &&
          (_vm.hoveredOption === option.value || _vm.selectedMonitoring === option.value),
        'red-border':
          option.value === 'generic-monitoring' &&
          (_vm.hoveredOption === option.value || _vm.selectedMonitoring === option.value),
      },staticStyle:{"max-width":"550px"},on:{"mouseenter":function($event){_vm.hoveredOption = option.value},"mouseleave":function($event){_vm.hoveredOption = null},"click":function($event){return _vm.selectMonitoringType(option.value)}}},[_c('div',{staticClass:"h-25 w-25 rounded-lg pl-4 pt-8 pb-0.5 pr-0.5",class:{
          'blue-bg-1': option.value === 'branded-monitoring',
          'red-bg-1': option.value === 'generic-monitoring',
        }},[_c('div',{staticClass:"h-full w-full rounded-tl-2xl rounded-br-md",class:{
            'blue-gradient': option.value === 'branded-monitoring',
            'red-gradient': option.value === 'generic-monitoring',
          }},[_c('div',{staticClass:"w-full flex gap-2 items-center pt-3 pl-3 pr-0.5"},[_c('div',{staticClass:"h-6 w-6 rounded-md",class:{
                'blue-bg-3 blue-icon-color': option.value === 'branded-monitoring',
                'red-bg-3 red-icon-color': option.value === 'generic-monitoring',
              }},[_c(option.icon,{tag:"component",staticClass:"my-auto mx-auto h-6"})],1),_c('div',{staticClass:"h-6 w-9 rounded-l-md",class:{
                'blue-bg-2': option.value === 'branded-monitoring',
                'red-bg-2': option.value === 'generic-monitoring',
              }})]),_c('div',{staticClass:"w-full flex items-center justify-end pr-0.5 pt-0.5"},[_c('div',{staticClass:"h-3 w-9 rounded-l-sm",class:{
                'blue-bg-4': option.value === 'branded-monitoring',
                'red-bg-4': option.value === 'generic-monitoring',
              }})]),_c('div',{staticClass:"w-full flex items-center justify-end pr-0.5 pt-0.5"},[_c('div',{staticClass:"h-2 w-9 rounded-l-xs",class:{
                'blue-bg-4': option.value === 'branded-monitoring',
                'red-bg-4': option.value === 'generic-monitoring',
              }})])])]),_c('div',{staticClass:"space-y-1"},[_c('div',{staticClass:"py-1 px-2 rounded-md w-fit text-xs font-bold",class:{
            'blue-text-color blue-bg-3': option.value === 'branded-monitoring',
            'red-text-color red-bg-3': option.value === 'generic-monitoring',
          }},[_c('p',[_vm._v(_vm._s(option.label))])]),_c('div',[_c('h5',{staticClass:"h5"},[_vm._v(_vm._s(option.title))]),_c('p',{staticClass:"text-xs font-normal"},[_vm._v(_vm._s(option.description))])])]),_c('div',{staticClass:"absolute top-0 right-0 p-2"},[_c('radio-button-v2',{attrs:{"is-selected":_vm.selectedMonitoring === option.value,"is-hovered":_vm.hoveredOption === option.value}})],1)])}),0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"space-y-8 px-8"},[_c('div',{staticClass:"space-y-2"},[_c('h4',{staticClass:"h4"},[_vm._v("What’s your goal?")]),_c('p',{staticClass:"text-sm font-normal"},[_vm._v("Choose your monitoring goal, and we'll tailor the experience to match.")])])])
}]

export { render, staticRenderFns }