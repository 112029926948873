<script>
export default {
  name: 'FinalStep',
}
</script>

<template>
  <div>FINAL STEP</div>
</template>

<style scoped lang="scss"></style>
