<template>
  <div>
    <div class="relative flex items-center py-2">
      <div
        v-for="(x, index) in steps"
        :key="`circle-${index}`"
        :style="`left: ${index * spaces}px`"
        class="above absolute h-2 w-2 rounded-full"
        :class="currentStep >= index + 1 ? activeColor : inactiveColor"
      ></div>
      <div
        v-for="(x, index) in steps - 1"
        :key="`line-${index}`"
        :style="`left: ${index * spaces}px; height: 1px`"
        class="absolute left-1 w-10 bg-neutral-100"
        :class="currentStep >= index + 2 ? activeColor : inactiveColor"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OnboardingStepper',
  props: {
    currentStep: {
      type: Number,
      default: 1,
    },
    steps: {
      type: Number,
      default: 3,
    },
    spaces: {
      type: Number,
      default: 40,
    },
    activeColor: {
      type: String,
      default: 'bg-bb-brand-purple',
    },
    inactiveColor: {
      type: String,
      default: 'bg-neutral-100',
    },
  },
  data() {
    return {}
  },
}
</script>

<style scoped lang="scss">
.above {
  z-index: 1;
}
</style>
