<template>
  <div class="bg-white rounded-lg border duration-300 ease-linear">
    <div
      class="flex items-center justify-between px-5 py-6 cursor-pointer"
      @click="$emit('click')"
    >
      <div class="flex items-center">
        <slot
          name="icon"
          :is-expanded="isExpanded"
        ></slot>
        <h5 class="h5">{{ title }}</h5>
      </div>
      <radio-button-v2
        :size="16"
        :is-hovered="false"
        :is-selected="isExpanded"
      />
    </div>
    <transition name="fade">
      <div
        v-if="isExpanded"
        class="p-4"
      >
        <slot name="content"></slot>
      </div>
    </transition>
  </div>
</template>

<script>
import RadioButtonV2 from '@/components/input/brightbid/RadioButtonV2.vue'

export default {
  name: 'ExpandableContainer',
  components: {
    RadioButtonV2,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    isExpanded: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
